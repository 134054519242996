@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-speer-blue {
  background:#084887;
}

.text-speer-blue {
  color:#084887;
}

.bg-speer-yellow {
  background:#F58A07;
}

.text-speer-yellow {
  color:#F58A07;
}

.h-app {
  height: calc(100vh - 3.5rem);
}

.w-sidebar {
  width: 350px;
}

@media (min-width: 1024px) {
  .h-app {
    height: calc(100vh - 6rem);
  }
}
