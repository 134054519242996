*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Lato', Arial, sans-serif;
  }

.app {
  min-height: 100vh;
}

a{
  text-decoration: none;
  color: inherit;
}